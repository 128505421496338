import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import sendForm from './POST';
import sendFormToTelegram from './postToTelegram';
import Button from '../UI/Button';
import './Form2.css';

const LINK_ID = 457;


const Form2 = ({ip, handleOpenModalThanks, handleCloseModal}) => {
  // const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [data, setData] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [validationErrors, setValidationErrors] = useState({phone: null, data: null, checkbox: null});
  const [errorTimeout, setErrorTimeout] = useState(null);
  const [formSubmitted,
    setFormSubmitted] = useState(false);

  useEffect(() => {
    if (errorTimeout) {
      clearTimeout(errorTimeout);
    }
    setErrorTimeout(
      setTimeout(() => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          phone: "",
          data: "",
          checkbox: "",
        }));
      }, 5000)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, data, checkbox]);

  const handleCheckBoxCahange = () => {
    setCheckbox(!checkbox);
  }
  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^[\d+]*$/.test(value)) {
      setPhone(value);
    }
  }
  const handleDataChange = (e) => {
    setData(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formSubmitted) {
      return; 
    }

    if (!phone) {
      setValidationErrors({
        ...validationErrors,
        phone: 'Введите Ваш телефон ',
        
      })
      return;
    }
    if (phone.length < 9) {
      setValidationErrors({
        ...validationErrors,
        phone: 'Введите корректный телефон'
      })
      return;
    }

    if (!data) {
      setValidationErrors({
        ...validationErrors,
        data: 'Заполните поле'
      })
      return;
    }

    if (!checkbox) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        checkbox: "Вы должны согласиться с условиями",
      }));
      return;
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        checkbox: "", 
      }));
    }


    const formDataToSend = {
      description: data,
      link_id: LINK_ID,
      fname: 'none',
      lname: 'none',
      email: 'none',
      source: 'ВСТАВИТЬ',
      fullphone: phone,
      country: 'Ru',
      language: 'RU',
      ip: ip,
    };

    setFormSubmitted(true);

    console.log(formDataToSend);

    // sendForm(formDataToSend)
    // .then(() => {
    //   return sendFormToTelegram(formDataToSend);
    // })
    // .then(response => {
    //   console.log("Data sent successfully:", response);

    //   console.log("Form data:", formDataToSend);
    //   handleOpenModalThanks();
    //   handleCloseModal();
    // })
    // .catch(error => {
    //   console.error("Failed to send data:", error);
    // });

    console.log("Form data:", formDataToSend);
    console.log("Form submitted successfully");

    setPhone("");
    setData("");
  }

  return (
    <div className="form-top2-main">
      <h2 className="form-top2__heading">Заказать звонок - консультацию</h2>
      <p className="form-top2__text">Оставьте свой номер телефона и мы вам перезвоним в ближайшее время.</p>
      <form className="form-top2" onSubmit={handleSubmit}>

        <div className="form-top2__input-wrapper">
          <input
            className="form-top2__input"
            type="text"
            name="phone"
            value={phone}
            onChange={handlePhoneChange}
            maxLength={13}
            placeholder="Ваш телефон"
            autoComplete='tel'
            />
          <span className="form-top2__input-error error">{validationErrors.phone}</span>
          <textarea
            className="form-top2__input form-top2__input--textarea"
            name="data"
            value={data}
            onChange={handleDataChange}
            placeholder="Опишите Вашу проблему"
            autoComplete='off'
            />
          <span className="form-top2__input-error error">{validationErrors.data}</span>
        </div>
        <div className="checkbox-wrapper">
          <label className="form-top2__label">
            <input
              className="form-top2__checkbox"
              type="checkbox"
              name="checkbox"
              id="checkbox"
              value={checkbox}
              onChange={handleCheckBoxCahange}/>
            <span>Нажимая кнопку, Вы соглашаетесь с &nbsp; <br />
            <Link className="form__link" to="/politics">политикой конфиденциальности</Link></span> 
          </label>
          <span className="form-top2__input-error error">{validationErrors.checkbox}</span>
        </div>
        <div className="form-top2__button-wrapper">
          <Button text="Позвоните мне!"/>
        </div>
      </form>
      <p className="form-top2__text form-top2__text--bottom">Все данные конфиденциальны.</p>
    </div>
  )
}
export default Form2