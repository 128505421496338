import React from 'react';
import { IoPhonePortraitOutline } from "react-icons/io5";
import logo from './Our-logo.png'
import Burger from '../Burger/Burger';
import MobileMenu from '../MobileMenu/MobileMenu';
import BasicModal from '../Modal/Modal';
import './Header.css';

function Header({isOpenMenu, handleToggleMenu, handleOpenModalThanks}) {
  return (
    <header className="header">
      <div className="container container--header">
        <div className="header__wrapper">
          <div className="header__row">
            <div className="header__logo-wrapper">
              <a href="/pravo"><img src={logo} alt="logo"/>
                <span className='header__logo-wrapper-text-big'>ЮРИДИЧЕСКИЙ ЦЕНТР</span>
                <span className='header__logo-wrapper-text-small'>Департамент правовой помощи гражданам</span>
              </a>
            </div>
            <div className="header__phone">
             <a href="tel:+79690745855" className="header__phone-link"><IoPhonePortraitOutline/> +7 969 074-58-55</a>
              <BasicModal text="ЗАКАЗАТЬ ЗВОНОК" handleOpenModalThanks={handleOpenModalThanks} />
            </div>
          </div>
          <div className="header__row-bottom">
            <nav className="nav">
              <ul className='header__list'>
                <li className="header__item">
                  <a href="#why-us">Почему мы?</a>
                </li>
                <li className="header__item">
                  <a href="#about-us">О нас</a>
                </li>

                <li className="header__item">
                  <a href="#how-it-work">Как мы работаем?
                  </a>
                </li>

                <li className="header__item">
                  <a href="#questions">Популярные вопросы</a>
                </li>
              </ul>
            </nav>
            <div className="header__burger">
              <Burger isOpenMenu={isOpenMenu} handleToggleMenu={handleToggleMenu}/>
            </div>
          </div>
          <div className="top_right"></div>
          <MobileMenu isOpenMenu={isOpenMenu} handleToggleMenu={handleToggleMenu}/>
        </div>
      </div>
    </header>
  );
}

export default Header;
