import './Burger.css';

const Burger = ({ isOpenMenu, handleToggleMenu}) => {
  
  return (
    <button className={isOpenMenu ? 'burger active' : 'burger'} onClick={handleToggleMenu} >
      <span className="burger__line"></span>
      <span className="burger__line"></span>
      <span className="burger__line"></span>
    </button>
  );
}
export default Burger