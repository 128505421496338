import React from 'react';
import FormTop from '../FormTop/FormTop';
import {IoMdCheckmarkCircleOutline} from "react-icons/io";
import finma from './FINMA Logo.png';

import './Promo.css';

function Header({ip, handleOpen}) {
  return (
    <section className="promo">
      <div className="container">
        <div className="promo__wrapper">
          <h1 className="promo__heading">ДЕПАРТАМЕНТ ЮРИДИЧЕСКОЙ ПОМОЩИ</h1>
          <p className="promo__text">Мы - юридическая компания в Казахстане сотрудничающая с &nbsp; 
                    <span>
                      <a href="https://www.finma.ch/"><img src={finma} alt="" /></a>
                      <br />
                      &nbsp;(Swiss Financial Market Supervisory Authority)
                    </span>, обеспечивая высокий стандарт качества и надежности в финансовых услугах.</p>
          <div className="promo__inner">
            <div className="promo__content-wrapper">
              <div className="promo__content">
                <p className="promo__content-text">Обращаясь к нам за помощью, вы можете:
                </p>
                <ul className="promo__list">
                  <li className="promo__list-item"><IoMdCheckmarkCircleOutline/>
                    <p>
                      <span>Получить поддержку
                      </span> 
                      &nbsp;в защите ваших конституционных прав и в взаимодействии с прокуратурой, силовыми
                      ведомствами и другими государственными органами.</p>
                  </li>
                  <li className="promo__list-item"><IoMdCheckmarkCircleOutline/>
                    <p>
                      <span>Получить бесплатную консультацию
                      </span>
                      &nbsp;от квалифицированного специалиста по любому правовому вопросу и рекомендации по
                      его разрешению.</p>
                  </li>
                  <li className="promo__list-item"><IoMdCheckmarkCircleOutline/>
                    <p>
                      <span>Профессионально подготовить и отправить
                      </span>
                      &nbsp;необходимые документы в Прокуратуру, Суд, и другие инстанции,
                      включая жалобы, заявления, судебные иски и т.д.</p>
                  </li>

                </ul>
                <div className="promo__content-bottom">
                  <p className="promo__content-bottom-text">
                  Для получения консультации отправите Ваш вопрос с помощью электронной формы или позвоните по телефону: +7 499 577-01-98
                  </p>
                </div>

              </div>
            </div>
            <div className="promo__form-wrapper" id='get-consultation'>
              <FormTop ip={ip} handleOpen={handleOpen}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;