import CustomizedAccordions from "../Accordion/Accoridon";
import "./Faq.css";

const Faq = () => {
  return (
    <section className="faq" id="questions">
      <div className="container">
        <div className="faq__wrapper">
          <h2 className="faq__heading">
            Часто задаваемые вопросы</h2>
          <div className="faq__accordion">
            <CustomizedAccordions/>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Faq;
