import "./Why.css";
const Why = () => {
  return (
    <div className="why" id="why-us">
      <div className="container">
        <div className="why__wrapper">
          <div className="why__heading-wrapper">
            <h2 className="why__heading">Почему мы?</h2>
          </div>
          <ul className="why__list">
            <li className="why__item">
              <span>01</span>
              <p className="why__item-title">Высокий профессионализм</p>
              <p className="why__item-text">
                Наши юристы имеют многолетний опыт и глубокие знания в различных областях права,
                что позволяет нам эффективно решать даже самые сложные дела.
              </p>
            </li>
            <li className="why__item">
              <span>02</span>
              <p className="why__item-title">Индивидуальный подход</p>
              <p className="why__item-text">Мы внимательно изучаем каждое дело и предлагаем
                решения, которые максимально соответствуют интересам и потребностям наших
                клиентов.</p>
            </li>
            <li className="why__item">
              <span>03</span>
              <p className="why__item-title">Конфиденциальность</p>
              <p className="why__item-text">
                Мы гарантируем полную конфиденциальность и безопасность предоставленной
                информации, обеспечивая защиту личных данных наших клиентов.
              </p>
            </li>
            <li className="why__item">
              <span>04</span>
              <p className="why__item-title">Высокий уровень успешности</p>
              <p className="why__item-text">
                Наша юридическая контора предлагает комплексные услуги, охватывающие все
                основные направления права, включая гражданское, уголовное и корпоративное
                право.
              </p>
            </li>
            <li className="why__item">
              <span>05</span>
              <p className="why__item-title">Оперативность и доступность</p>
              <p className="why__item-text">Мы обеспечиваем быструю и качественную юридическую
                помощь, предоставляя консультации и решения в кратчайшие сроки.</p>
            </li>
            <li className="why__item">
              <span>06</span>
              <p className="why__item-title">
                Широкий спектр услуг</p>
              <p className="why__item-text">
                Наша юридическая контора предлагает комплексные услуги, охватывающие все
                основные направления права, включая гражданское, уголовное и корпоративное
                право.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Why;
