import * as React from 'react';
import {styled} from '@mui/material/styles';
// import IoIosArrowForward from '@mui/icons-material/ArrowForwardIosSharp';
import {IoIosArrowForward} from "react-icons/io";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import './Accordion.css';

const Accordion = styled((props) => (<MuiAccordion disableGutters elevation={0} square {...props}/>))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&::before': {
    display: 'none'
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={< IoIosArrowForward sx = {{ fontSize: '0.9rem' }}/>}
    {...props}/>
))(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(255, 255, 255, .05)'
    : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

export default function CustomizedAccordions() {
  const [expanded,
    setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded
      ? panel
      : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        className='accordion-wrapper'>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          className='item'>
          <Typography className='title-accordion'>Программа юридического анализа</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='text'>
            Наша команда предоставляет персонализированные консультации и помощь в оценке
            различных юридических вопросов, помогая восстановить справедливость и защитить
            ваши права.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography className='title-accordion'>Защитные меры для клиентов</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='text'>
            Наши специалисты разрабатывают защитные стратегии для клиентов, обеспечивая им
            юридическую поддержку и защиту их интересов.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography className='title-accordion'>Программа компенсации ущерба</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='text'>
            Мы помогаем нашим клиентам получить компенсацию за причиненный ущерб через
            юридические и альтернативные механизмы, предоставляя полное сопровождение на
            всех этапах.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography className='title-accordion'>Превентивные меры по снижению правовых рисков</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='text'>
            Мы не только решаем возникшие правовые вопросы, но и принимаем проактивные меры
            по предотвращению таких ситуаций в будущем, обучая клиентов и внедряя
            профилактические программы.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>

  );

}