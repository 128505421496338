import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';

import Form2 from '../Form2/Form2';
import './ModalBottom.css';

export default function TransitionsModal({text, ip, handleOpenModalThanks}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  return (
    <div className='modal'>
      <Button className='modal__button-bottom' onClick={handleOpen}>{text}</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{
        backdrop: Backdrop
      }}
        slotProps={{
        backdrop: {
          timeout: 500
        }
      }}>
        <Fade in={open}>
          <Box sx={style} className='modal-content'>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2">
              <div className="modal__header">
                <p className='modal-close' onClick={handleClose}>╳</p>
              </div>
              <Form2 ip={ip} handleCloseModal={handleClose} handleOpenModalThanks={handleOpenModalThanks} />
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

// export default function BasicModal({text}) {   const [open, setOpen] =
// React.useState(false);   const handleOpen = () => setOpen(true);   const
// handleClose = () => setOpen(false);   return (     <div className='modal'>
//    <Button className='button--modal' onClick={handleOpen}>{text}</Button>
//   <Modal         open={open}         onClose={handleClose}
// aria-labelledby="modal-modal-title"
// aria-describedby="modal-modal-description"       >         <Box sx={style}
// className='modal-content'>           <div className="modal__header"><p
// className='modal-close' onClick={handleClose}>╳</p></div>           <Form2 />
//         </Box>       </Modal>     </div>   ); }
