import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Promo from "../Promo/Promo";
import Any from "../Any/Any";
import Header from "../Header/Header";
import Help from "../Help/Help";
import Why from "../Why/Why";
import Footer from "../Footer/Footer";
import FAQ from "../FAQ/Faq";
import Questions from "../Questions/Questions";
import How from "../How/How";
import Agree from '../Agree/Agree'
import Politics from "../Politics/Politics";
import TransitionsModal from '../ModalThanks/ModalThanks'
import "./App.css";


const siteDomain = window.location.origin;


const data = {
  name: "ООО «ДЕПАРТАМЕНТ ЮРИДИЧЕСКОЙ ПОМОЩИ»",
  url: siteDomain,
}


function App() {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [userIP, setUserIP] = useState("");
  const handleToggleMenu = () => setIsOpenMenu(!isOpenMenu);

  //modalThanks

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error("Error fetching the IP address:", error);
      }
    };

    fetchIP();
  }, []);
  


  return (
    <Router>
      <Routes>
        <Route
          path="*"
          element={
            <div>

              <TransitionsModal open={open} handleClose={handleClose} />
              <Header isOpenMenu={isOpenMenu} handleToggleMenu={handleToggleMenu} 
                handleOpenModalThanks={handleOpen} 
                />
              <main
                style={{ display: isOpenMenu ? "none" : "block" }}
                className="main"
              >
              <Promo ip={userIP} handleOpen={handleOpen}  />
              <Any/>
              <Help/>
              <Why/>
              <How/>
              <FAQ/>
              <Questions ip={userIP} handleOpenModalThanks={handleOpen} />
              
              </main>
              <Footer data={data} />
            </div>
          }
        />
        <Route path="/agree" element={<Agree data={data}/>} />
        <Route path="/politics" element={<Politics data={data}/>} />
      </Routes>
    </Router>
  );
}

export default App;
