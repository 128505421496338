import {Link} from 'react-router-dom';
import logo from './Our-logo.png'
import finma from './FINMA Logo.png' 
import "./Footer.css";

const Footer = ({data}) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">

          <div className="footer__top">
            <div className="footer__top-wrapper">
              <ul className="footer__top-row">
                <li className="footer__top-item">
                  <div className="footer__logo-wrapper">
                    <a href="/"><img src={logo} alt="logo"/> 
                    </a>
                  </div>
                  <p className="footer__top-text">
                    Юридическая компания, оказывающая услуги для всех граждна Казахстана, в том числе и для
                    мигрантов. <br />
                    В партнерстве с <a href="https://www.finma.ch/"><img src={finma} alt="" /></a>.
        
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer__copywrite">
            <p className="footer__copywrite-text">
              © 2024 {data.name}. Все права защищены.
            </p>
            <div className="footer__copywrite-links">
              <Link to="/politics" className="footer__copywrite-link">Политика конфиденциальности</Link>
              <Link to="/agree" className="footer__copywrite-link">Соглашение на обработку данных</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
