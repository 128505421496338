import './Help.css';
const Help = () => {
  return (
<section className="help">
  <div className="container">
    <div className=" help__wrapper">
      <div className="help__heading-wrapper">
        <h2 className="help__heading">
          Обеспечение защиты интересов и поддержка
        </h2>
      </div>
      <ul className="help__list">
        <li className="help__item">
          <p className="help__title">Жилищное право, сопровождение сделок с недвижимостью</p>
          <p className="help__text">Помощь в решении вопросов, связанных с финансами.</p>
        </li>
        <li className="help__item">
          <p className="help__title">Вопросы инвестиций</p>
          <p className="help__text">Помощь в анализе и разрешении инвестиционных споров.</p>
        </li>
        <li className="help__item">
          <p className="help__title">Другие юридические вопросы</p>
          <p className="help__text">Мы готовы рассмотреть различные юридические вопросы и предложить индивидуальный подход к их решению.</p>
        </li>
      </ul>
    </div>
  </div>
</section>

  )
}
export default Help