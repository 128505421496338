import {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Button from '../UI/Button';
import sendForm from './POST';
import sendFormToTelegram from './postToTelegram';

import './FormTop.css';

const LINK_ID = 457;

const FormTop = ({ip, handleOpen}) => {
  const navigate = useNavigate();
  const [phone,
    setPhone] = useState('');
  const [data,
    setData] = useState('');
  const [checkbox,
    setCheckbox] = useState(false);
  const [name,
    setName] = useState('');
  const [email,
    setEmail] = useState('');
  const [company,
    setCompany] = useState('');
  const [year,
    setYear] = useState('');
  const [damage,
    setDamage] = useState('');
  const [validationErrors,
    setValidationErrors] = useState({data: null});
  const [errorTimeout,
    setErrorTimeout] = useState(null);
  const [formSubmitted,
    setFormSubmitted] = useState(false);

  useEffect(() => {
    if (errorTimeout) {
      clearTimeout(errorTimeout);
    }
    setErrorTimeout(setTimeout(() => {
      setValidationErrors({data: ''});
    }, 4000));
  }, [data]);

  const handleCheckBoxChange = () => {
    setCheckbox(!checkbox);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^[\d+]*$/.test(value)) {
      setPhone(value);
    }
  };

  const handleDataChange = (e) => {
    setData(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  const handleDamageChange = (e) => {
    setDamage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formSubmitted) {
      return;
    }

    let errors = {};

    if (!phone) {
      errors.data = 'Введите Ваш телефон';
    } else if (phone.length < 9) {
      errors.data = 'Введите корректный телефон';
    }

    if (!data) {
      errors.data = 'Заполните поле';
    }

    if (!checkbox) {
      errors.checkbox = 'Вы должны согласиться с условиями';
    }
    if (!damage) {
      errors.data = 'Введите сумму ущерба';
    }

  
    if (!year || isNaN(year)) {
      errors.data = 'Введите корректный год';
    }



    if (!company) {
      errors.data = 'Введите компанию';
    }


    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errors.data = 'Введите корректный емейл';
    }

    if (!name) {
      errors.data = 'Введите имя и фамилию';
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    const formDataToSend = {
      description: data,
      link_id: LINK_ID,
      name,
      email,
      company,
      year,
      damage,
      source: 'ВСТАВИТЬ',
      fullphone: phone,
      country: 'Ru',
      language: 'RU',
      ip
    };

    console.log(formDataToSend);

    setFormSubmitted(true);

    // Uncomment and implement the actual sending logic
    // sendForm(formDataToSend).then(() => {   return
    // sendFormToTelegram(formDataToSend); }).then(response => {   console.log("Data
    // sent successfully:", response);   handleOpen(); }).catch(error => {
    // console.error("Failed to send data:", error); });

    setData('');

  };

  return (
    <div className="form-top-main">
      <div className="form-top__wrapper">
        <p className="form-top__text">
          Оставьте номер телефона и Ваш вопрос.
          <br/>
          Cпециалист свяжется с Вами для решения вашей проблемы
        </p>
        <form className="form-top" onSubmit={handleSubmit}>
          <div className="form-top__input-wrapper">
            <input
              className="form-top__input"
              type="text"
              name="name"
              value={name}
              onChange={handleNameChange}
              placeholder="Ваши имя и фамилия"
              autoComplete="off"/>

            <input
              className="form-top__input"
              type="text"
              name="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Ваш e-mail"
              autoComplete="off"/>

            <input
              className="form-top__input"
              type="text"
              name="company"
              value={company}
              onChange={handleCompanyChange}
              placeholder="Компания"
              autoComplete="off"/>

            <input
              className="form-top__input"
              type="text"
              name="year"
              value={year}
              onChange={handleYearChange}
              placeholder="Год"
              autoComplete="off"/>

            <input
              className="form-top__input"
              type="text"
              name="damage"
              value={damage}
              onChange={handleDamageChange}
              placeholder="Сумма ущерба"
              autoComplete="off"/>

            <input
              className="form-top__input form-top__input--phone"
              type="text"
              name="phone"
              maxLength={13}
              value={phone}
              onChange={handlePhoneChange}
              placeholder="Ваш телефон"
              autoComplete="off"/>

            <textarea
              className="form-top__input form-top__input--textarea"
              name="data"
              value={data}
              onChange={handleDataChange}
              placeholder="Опишите Вашу проблему"
              autoComplete="off"/>
            <span className="form-top__input-error error">{validationErrors.data}</span>
          </div>
          <div className="checkbox-wrapper">
            <label className="form-top__label">
              <input
                className="form-top__checkbox"
                type="checkbox"
                name="checkbox"
                id="checkbox"
                checked={checkbox}
                onChange={handleCheckBoxChange}/>
              <span>Нажимая кнопку, Вы соглашаетесь с &nbsp;
                <br/>
                <Link className='form__link' to='/politics'>политикой конфиденциальности</Link>
              </span>
            </label>
            <span className="form-top__input-error error">{validationErrors.checkbox}</span>
          </div>
          <div className="form-top__button-wrapper">
            <Button text="Получить консультацию"/>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormTop;
