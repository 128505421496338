import TransitionsModal from "../ModalBotom/ModalBottom";
import {BsArrowBarUp} from "react-icons/bs";
import "./Questions.css";


const Questions = ({ip, handleOpenModalThanks}) => {
  return (
    <section className="questions">
      <div className="container">
        <div className="questions__wrapper">
        <a href="#top" className="link-to-top">
        <BsArrowBarUp style={{color:"white"}}/></a>
          <div className="questions__row">
     
            <div className="questions__heading-wrapper">
              <h3 className="questions__heading">
                Оставьте заявку на консультацию со специалистом
              </h3>
              <div className="questions__text-wrapper">
                <p className="questions__text">
                  Оставьте свои данные и наш консультант свяжется с вами в ближайшее время
                </p>
              </div>
            </div>
            <div className="questions__form">
              <div className="questions__form-wrapper">
                <TransitionsModal ip={ip} handleOpenModalThanks={handleOpenModalThanks}  text="ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};
export default Questions;
