import './MobileMenu.css';

const MobileMenu = ({isOpenMenu, handleToggleMenu}) => {
  return (
    <div className={`mobile-menu ${isOpenMenu
      ? 'active'
      : ''}`}>
        <div
          style={{
          display: isOpenMenu
            ? 'flex'
            : 'none'
        }}
          className="mobile-menu__wrapper">
          <ul className="mobile-menu__list">
            <li onClick={handleToggleMenu} className="mobile-menu__item">
              <a href="#why-us" className="mobile-menu__link">Почему мы?</a>
            </li>
            <li onClick={handleToggleMenu} className="mobile-menu__item">
              <a href="#about-us" className="mobile-menu__link">О нас</a>
            </li>
            <li onClick={handleToggleMenu} className="mobile-menu__item">
              <a href="#how-it-work" className="mobile-menu__link">Как мы работаем?</a>
            </li>
            <li onClick={handleToggleMenu} className="mobile-menu__item">
              <a href="#questions" className="mobile-menu__link">Популярные вопросы</a>
            </li>
          </ul>
      </div>
    </div>
  )
}
export default MobileMenu