import img1 from './chat.png'
import img2 from './def.png'
import img3 from './consultation.png'

import "./How.css";

const How = () => {
  return (
    <section className="how" id="how-it-work">
      <div className="container">
        <div className="how__wrapper">

          <div className="heading__wrapper">
            <h2 className="how__heading">
              Как мы осуществляем консультацию
            </h2>
          </div>
          <div className="how__content">
            <div className="how__item">
              <ul className="how__list">
                <li className="how__list-item">Вы оставляете заявку на сайте.</li>
                <li className="how__list-item">Наш юрист свяжется с вами в ближайшее время.</li>
                <li className="how__list-item">Вы получаете ответ на свой вопрос.</li>
                <li className="how__list-item">Mы предложим вам несколько вариантов решения ситуации.</li>
              </ul>
            </div>
            <div className="how__item how__item-box">
              <div className="how__item-box-inner">
                <ul className="how__list-box">
                  <li className="how__list-box-item">
                    <div className="how__list-box-img">
                      <img src={img2} alt="chat"/>
                    </div>
                    <div className="how__list-box-content">
                      <p className="how__list-box-text">
                        <span>Гарантируем конфиденциальность </span>
                        при общении с вами</p>
                    </div>
                  </li>
                  <li className="how__list-box-item">
                    <div className="how__list-box-img">
                      <img src={img1} alt="def"/>
                    </div>
                    <div className="how__list-box-content">
                      <p className="how__list-box-text">Эксперты отвечают на Ваши вопросы <span>
                          в режиме 24/7</span>
                      </p>
                    </div>
                  </li>
                  <li className="how__list-box-item">
                    <div className="how__list-box-img">
                      <img src={img3} alt="consultation"/>
                    </div>
                    <div className="how__list-box-content">
                      <p className="how__list-box-text">
                        <span>Юридические консультации </span>
                        на разные сферы</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default How;
