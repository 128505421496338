import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './ModalThanks.css';
// import { teal } from '@mui/material/colors';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -60%)',
  width: 500,
  height: 200,
  textAlign: 'center',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal({open, handleClose}) {


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 600,
          },
        }}
      >
        <Fade in={open}>
          <Box className='box'>
            <Typography className='title' id="transition-modal-title" variant="h6" component="h2" pb={1} >
              Ваша заявка принята
            </Typography>
            <Typography className='text' id="transition-modal-description" sx={{ mt: 2 }} >
              Мы свяжемся с вами в ближайшее время
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}