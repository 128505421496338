import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Form2 from '../Form2/Form2';
import './Modal.css';



export default function BasicModal({text, handleOpenModalThanks}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className='modal'>
      <Button className='button--modal' onClick={handleOpen}>{text}</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style} className='modal-content'>
          <div className="modal__header"><p className='modal-close' onClick={handleClose}>╳</p></div>
          <Form2 handleCloseModal={handleClose} handleOpenModalThanks={handleOpenModalThanks}  />
        </Box>
      </Modal>
    </div>
  );
}


