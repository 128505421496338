import './Any.css';

const Any = () => {
  return (
    <section className="any" id="about-us">
      <div className="container">
        <div className="any__wrapper">
          <div className="any__heading-wrapper">
            <h2 className="any__heading">
              Мы беремся за дела любой сложности
            </h2>
            <p className="any__text">
              Кроме того, есть еще несколько преимуществ работы с нами
            </p>
          </div>
          <div className="any__content">
            <div className="any__content-row">
              <div className="any__content-item">
                <div>
                  <p className="any__content-number">72</p>
                  <p className="any__content-text">Лучших юриста со всей страны</p>
                </div>
                <div>
                  <p className="any__content-number">16 лет</p>
                  <p className="any__content-text">Средний стаж наших экспертов</p>
                </div>
              </div>
              <div className="any__content-item any__content-item--middle">
                <div className="any__content-item--middle-inner">
                  <p className="any__content-item--middle-text" >ДЕПАРТАМЕНТ ЮРИДИЧЕСКОЙ ПОМОЩИ</p>
                </div>
              </div>
              <div className="any__content-item">
                <div>
                  <p className="any__content-number">1020</p>
                  <p className="any__content-text">Людей получают консультации ежедневно</p>
                </div>
                <div>
                  <p className="any__content-number">78%</p>
                  <p className="any__content-text">Завершено дел в нашу пользу</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Any